<template>
    <div class="row">
        <div
            v-for="(c, ck) in layout_temp_class[setting.layout]"
            :class="c + (setting.config[ck].class ? ' ' + setting.config[ck].class : '')"
            :key="`${from}_${ck}`"
        >
            <!-- 額外內容插槽 -->
            <div v-if="extra != ''">
                <component type="grid" :is="extra" :from="`${from}_${ck}`" :click="click"></component>
            </div>
            <!-- <div v-if="setting.config[ck].type=='grid'"
                 :class="setting.config[ck].type+(setting.config[ck].class?' '+setting.config[ck].class:'')"
                 :style="setting.config[ck].style?setting.config[ck].style:''">
                <row  v-for="(g,gk) in setting.config[ck].content"
                     v-model="setting.config[ck].content[gk]"
                     :key="`${ck}_content_${gk}`"
                     :from="`${ck}_content_${gk}`"></row>
            </div> -->
            <div v-if="setting.config[ck].type == 'img'">
                <img
                    style="width: 100%"
                    :src="setting.config[ck].content"
                    :class="setting.config[ck].type + (setting.config[ck].class ? ' ' + setting.config[ck].class : '')"
                    :style="setting.config[ck].style ? setting.config[ck].style : ''"
                />
                <div
                    class="text"
                    style="font-size: 1.2em"
                    v-if="setting.config[ck].imgtext"
                    v-show="setting.config[ck].imgtext != ''"
                    :class="setting.config[ck].iclass ? setting.config[ck].iclass : ''"
                    :style="setting.config[ck].istyle ? setting.config[ck].istyle : ''"
                    v-html="$nl2br(setting.config[ck].imgtext)"
                ></div>
            </div>
            <h1
                style="font-size: 3em; color: #3c3c3c"
                v-else-if="setting.config[ck].type == 'title'"
                :class="setting.config[ck].type + (setting.config[ck].class ? ' ' + setting.config[ck].class : '')"
                :style="setting.config[ck].style ? setting.config[ck].style : ''"
            >
                <strong>{{ setting.config[ck].content }}</strong>
            </h1>
            <div
                style="font-size: 1.2em"
                v-else-if="setting.config[ck].type == 'text'"
                :class="setting.config[ck].type + (setting.config[ck].class ? ' ' + setting.config[ck].class : '')"
                :style="setting.config[ck].style ? setting.config[ck].style : ''"
                v-html="$nl2br(setting.config[ck].content)"
            ></div>
            <ul v-else-if="setting.config[ck].type == 'list'">
                <li v-for="(l, lk) in setting.config[ck].content" :key="`li_${ck}_${lk}`" v-html="$nl2br(l)"></li>
            </ul>
            <div
                v-else-if="setting.config[ck].type == 'video'"
                :class="setting.config[ck].type + (setting.config[ck].class ? ' ' + setting.config[ck].class : '')"
                :style="setting.config[ck].style ? setting.config[ck].style : ''"
            >
                <a
                    v-for="(v, vk) in setting.config[ck].content"
                    :key="`vdo_${vk}`"
                    :class="v.class ? v.class : ''"
                    @click="showVideo(v.url ? v.url : '')"
                >
                    <i class="fab fa-youtube"></i>
                    &nbsp;{{ v.name || '' }}
                </a>
            </div>
            <div
                style="font-size: 1.2em"
                v-else-if="setting.config[ck].type == 'table'"
                :class="setting.config[ck].type + (setting.config[ck].class ? ' ' + setting.config[ck].class : '')"
                :style="setting.config[ck].style ? setting.config[ck].style : ''"
            >
                <table
                    :class="setting.config[ck].table.class ? setting.config[ck].table.class : ''"
                    :style="setting.config[ck].table.style ? setting.config[ck].table.style : ''"
                >
                    <thead v-if="setting.config[ck].table.th.length">
                        <tr>
                            <th
                                v-for="(th, thk) in setting.config[ck].table.th"
                                :key="`${ck}_th_${thk}`"
                                :colspan="th.colspan"
                                :rowspan="th.rowspan"
                                :style="th.style ? th.style : ''"
                                v-html="$nl2br(th.content)"
                            ></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(tr, trk) in setting.config[ck].table.tr" :key="`${ck}_tbody_${trk}`">
                            <td
                                v-for="(td, tdk) in tr"
                                :key="`${ck}_${trk}_td_${tdk}`"
                                :colspan="td.colspan"
                                :rowspan="td.rowspan"
                                :style="td.style ? td.style : ''"
                                v-html="$nl2br(td.content)"
                            ></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div
                v-else-if="setting.config[ck].type == 'iframe'"
                :class="setting.config[ck].type + (setting.config[ck].class ? ' ' + setting.config[ck].class : '')"
                :style="setting.config[ck].style ? setting.config[ck].style : ''"
            >
                <iframe
                    frameborder="0"
                    width="100%"
                    :src="setting.config[ck].url"
                    :style="`height:${setting.config[ck].height}px`"
                ></iframe>
            </div>
            <div
                data-info="empty"
                style="font-size: 1.2em"
                v-else
                :class="setting.config[ck].type"
                :style="setting.config[ck].style ? setting.config[ck].style : ''"
            ></div>
        </div>
    </div>
</template>
<script>
    /**
     * @param value 設定
     */
    // import row from "./row.vue";
    export default {
        name: 'grid',
        props: {
            from: {
                type: String,
                default() {
                    return '';
                },
            },
            value: {
                default() {
                    return {
                        type: 0,
                    };
                },
            },
        },
        inject: ['click', 'extra'],
        data() {
            return {
                layout_temp_class: [
                    /**
                     * 一個
                     * ┌───┐
                     * │ 0 │
                     * └───┘
                     */
                    ['col-12'],
                    /**
                     * 左右對分
                     * ┌───┬───┐
                     * │ 0 │ 1 │
                     * └───┴───┘
                     */
                    ['col-md-6', 'col-md-6'],
                    /**
                     * 三個分
                     * ┌───┬───┬───┐
                     * │ 0 │ 1 │ 2 │
                     * └───┴───┴───┘
                     */
                    ['col-md-4', 'col-md-4', 'col-md-4'],
                ],
            };
        },
        computed: {
            setting: {
                set(val) {
                    this.$emit('input', val);
                },
                get() {
                    return this.value;
                },
            },
        },
        methods: {
            showVideo(link) {
                let html = `<video controls autoplay name="media" style="width:100%;">`;
                for (let l in link) {
                    html += `<source src="${link[l]}">`;
                }
                html += ` </video>`;
                this.$swal.fire({
                    showConfirmButton: false,
                    showCloseButton: true,
                    title: ' ',
                    html: html,
                    width: 900,
                });
            },
        },
        // components: { row },
    };
</script>
