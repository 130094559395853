<template>
    <div>
        <div :class="rowclass"
             v-for="(g,gk) in config"
             :key="`${from}_${gk}`">
            <div v-if="extra!=''">
                <component type="row"
                           :is="extra"
                           :from="`${from}_${gk}`"
                           :click="click"></component>
            </div>
            <grid v-model="config[gk]"
                  :from="`${from}_${gk}_config`">
            </grid>
        </div>
    </div>
</template>
<script>
import grid from "./grid.vue";
export default {
  props: {
    from: {
      type: String,
      default() {
        return "0";
      },
    },
    rowclass: {
      type: String,
      default() {
        return "";
      },
    },
    extra: {
      default() {
        return "";
      },
    },
    value: {
      default() {
        return {
          type: 0,
        };
      },
    },
    click: {
      type: Function,
      default() {
        return Function;
      },
    },
  },
  provide() {
    return { click: this.click, extra: this.extra };
  },
  computed: {
    config: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
  components: { grid },
};
</script>