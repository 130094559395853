<template>
    <div class="container" style="background-color: #fff;">
        <div class="row">
            <div class="col-12 mb-3">
                <h1 class="title">{{ news.name }}</h1>
                <div>{{ news.date }}</div>
            </div>
            <div class="col-12 mb-5">
                <row v-model="news.content"></row>
            </div>
        </div>
        <div class="text-center">
            <a class="btn_top" href="/news">BACK</a>
        </div>
    </div>
</template>

<style lang="scss">
.btn_top {
    text-decoration: none;
    cursor: pointer;
    padding: 0px 30px;
    margin: 30px auto;
    font-weight: bold;
    display: inline-block;
    color: #a1a1a1;
    border: 2px solid #a1a1a1;

    &:hover {
        color: #f27f00;
        border: 2px solid #f27f00;
    }
}
</style>
<script>
import row from "../../components/row.vue";
export default {
    data() {
        return {
            news: {}
        };
    },
    watch: {
        $route() {
            this.init();
        }
    },
    mounted() {
        this.init();
    },
    computed: {},
    methods: {
        init() {
            let vue = this;
            //
            window.scroll(0, 0);
            // 機台資料
            vue.news = vue.$store.state.data.news.find(p => {
                return p.id == vue.$route.params.id;
            });
        }
    },
    components: {
        row
    }
};
</script>
